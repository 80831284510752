import React, { Component, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import vhCheck from 'vh-check'
import { useMediaQuery } from 'react-responsive'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'
// import FilePlayer from 'react-player/lib/players/FilePlayer'
import FilePlayer from 'react-player'
import useHasMounted from '../hooks/useHasMounted'
import getSizes from '../utils/getSizes'
import mod from '../utils/mod'
import canUseDom from '../utils/canUseDom'
import { MIN_TABLET_MQ } from '../utils/presets'
// TABLET_WIDTH,
import Img from './Image'
import {
  MouseContext,
  MouseDispatch,
  MouseCordsContext,
  NavDispatch,
  WaypointContext,
  SizeContext
} from './MouseContext'

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews)

const GalleryWrapper = styled.main({
  position: 'relative',
  alignItems: 'top',
  display: 'flex',
  height: '100vh',
  [MIN_TABLET_MQ]: {
    height: 'calc(100vh - 62px)', // it's different because we don't have a stacked nav
  },
  '.react-swipeable-view-container': {
    height: '100%'
  }
}, ({ offset }) => {
  return {
    height: `calc(100vh - ${offset}px)`,
  }
})

const ImgFigure = styled.figure({
  padding: '0 1rem',
  margin: 0,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100% - 3rem)',
  [MIN_TABLET_MQ]: {
    height: '100%',
  }
})

const GalleryFooter = styled.footer({
  padding: `0 1.5rem 1rem`,
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column-reverse',
  position: `absolute`,
  bottom: `1rem`,
  left: 0,
  right: 0,
  // height: `63px`,
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  [MIN_TABLET_MQ]: {
    height: `unset`,
    position: `relative`,
    bottom: `unset`,
    left: `unset`,
    right: `unset`,
    marginTop: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '28px 1.5rem 1rem',
  },
}, ({ offset }) => {
  return {
    bottom: offset
  }
})

const GalleryTitle = styled.h2({
  fontSize: '1rem',
  lineHeight: `1.5rem`,
  margin: 0,
  width: `100%`,
  minWidth: 0,
  whiteSpace: `nowrap`,
  textOverflow: `ellipsis`,
  overflow: `hidden`,
  position: `relative`,
  [MIN_TABLET_MQ]: {
    maxWidth: `33rem`,
  }
})

const FooterText = styled.div({
  fontSize: '1rem',
  lineHeight: `1.5rem`,
  // padding: `0.25rem 0`
})

const ImgCaption = styled(FooterText)({
  width: `100%`,
  minWidth: 0,
  whiteSpace: `nowrap`,
  textOverflow: `ellipsis`,
  overflow: `hidden`,
  [MIN_TABLET_MQ]: {
    position: `absolute`,
    left: 0,
    textAlign: `center`,
  }
})

const ImgCount = styled(FooterText)({
  width: '4rem',
  [MIN_TABLET_MQ]: {
    textAlign: 'right',
    top: 0,
    right: 0,
    position: `relative`
  }
})

const Title = styled(FooterText)({
  display: `inline-block`,
  // borderBottom: `1px solid transparent`,
  textDecorationLine: `none`,
  textUnderlineOffset: `0.25rem`,
}, ({ target, direction, waypoint, isTabletOrMobile }) => {
  let active = false
  if (isTabletOrMobile) {
    if (waypoint === "main") {
      active = true
    }
  } else {
    if (direction !== "down" && waypoint === "main") {
      active = true
    }
    if ((target === "main" || target === "footer") && waypoint === "footer") {
      active = true
    }
  }
  return {
    // borderBottom: active && `1px solid #000`,
    textDecorationLine: active && `underline`,
  }
});

const InfoLink = styled(FooterText)({
  color: `#000`,
  textAlign: `center`,
  marginLeft: `2rem`,
  position: `absolute`,
  bottom: 0,
  right: 0,
  // borderBottom: `1px solid transparent`,
  textDecorationLine: `none`,
  textUnderlineOffset: `0.25rem`,
  [MIN_TABLET_MQ]: {
    right: `auto`,
    bottom: `auto`,
    position: `relative`,
    display: `inline-block`
  }
}, ({ target, direction, waypoint, isTabletOrMobile }) => {
  let active = false
  if (isTabletOrMobile) {
    if (waypoint === "footer") {
      active = true
    }
  } else {
    if (direction === "down" && waypoint === "main") {
      active = true
    }
    if (waypoint === "footer" && target === "content") {
      active = true
    }
  }
  return {
    // borderBottom: active && `1px solid #000`
    textDecorationLine: active && `underline`,
  }
});

const VideoWrapper = styled(`div`)({
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 4rem',
}, ({ isReady }) => ({
  opacity: isReady ? 1 : 0,
}))

// video element
const Video = ({ video, playing = false, wWidth, wHeight }) => {
  const [isReady, setIsReady] = useState(false)
  const dispatch = useContext(MouseDispatch)
  const handleOnReady = () => { setIsReady(true) }
  const videoRef = useRef()
  let aspectRatio = 1
  if (videoRef.current) {
    aspectRatio = videoRef.current.player.player.player.videoWidth / videoRef.current.player.player.player.videoHeight
  }
  let width = aspectRatio < 1 ? `calc(100% * ${aspectRatio})` : '100%'
  if (wWidth >= wHeight) { // if landscape
    width = `calc(80vh * ${aspectRatio})`
  }
  return (
    // responsive wrapper with aspect ratio
    <VideoWrapper // wrapper
      key={video.url}
      isReady={isReady}
    >
      <FilePlayer
        ref={videoRef}
        onMouseEnter={() => dispatch({ type: "enter", item: "video" })}
        onMouseLeave={() => dispatch({ type: "exit" })}
        onReady={handleOnReady}
        url={[
          {src: `${video.url}`, type: 'video/mp4'},
          {src: `${video.url}/video/-/format/webm/`, type: 'video/webm'}
        ]}
        width={`${width}`}
        height={`auto`}
        muted={playing ? false : true}
        volume={100}
        playsinline
        controls
        playing={playing}
        loop
        style={{ cursor: `pointer` }}
        // config={{file: {}}}
      />
    </VideoWrapper>
  )
}

Video.propTypes = {
  video: PropTypes.object,
  playing: PropTypes.bool,
  wWidth: PropTypes.number,
  wHeight: PropTypes.number
}

const Image = (props) => {
  const { image, wWidth, wHeight, preloadIndex, index, viewIndex } = props

  // fix for rehydration issues caused by conditional rendering
  // of portrait or landscape image styles
  const hasMounted = useHasMounted();

  // Consider using useMediaQuery hook over wHeight/wWidth
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  // Consider passing `portrait` and `landscape` values for the `sizes` prop
  // and reusing them below for the outerCss.width
  const sizes = getSizes(image, 2400)
  const { aspectRatio } = sizes
  // if aspectRatio < 1 it's a portrait format image
  const outerCss = {
    width: aspectRatio < 1 ? `calc(100% * ${aspectRatio})` : '100%',
    opacity: 1
  }
  if (wWidth >= wHeight) { // if landscape
    outerCss.width = `calc(80vh * ${aspectRatio})`
  }

  return (
    <ImgFigure
      key={image.url}
    >
      <Img
        outerCss={hasMounted && outerCss}
        sizes={sizes}
        objectPosition={`center`}
        lazyload={index === preloadIndex[0] || index === preloadIndex[1] || index === viewIndex ? false : true}
      />
    </ImgFigure>
  )
}

Image.propTypes = {
  viewIndex: PropTypes.number,
  index: PropTypes.number,
  preloadIndex: PropTypes.array,
  image: PropTypes.object,
  wWidth: PropTypes.number,
  wHeight: PropTypes.number
}

class SwipeableGallery extends Component {

  constructor (props) {
    super(props)
    this.state = {
      viewIndex: 0,
      imgCaption: "Default value",
      preloadIndex: [1, props.images.length > 0 ? props.images.length : 0]
    }
  }

  handleChangeIndex = (viewIndex, indexLatest) => {
    console.log("handleChangeIndex: ", viewIndex, indexLatest);

    this.setState({
      viewIndex,
      indexLatest,
      swiped: true
    })

    // call preload here
    this.preload(viewIndex)
  }

  preload = (viewIndex) => {
    const { images } = this.props
    const count = images.length > 0 ? images.length : 0
    let nextIndex = viewIndex
    let prevIndex = viewIndex
    nextIndex += 1
    prevIndex -= 1
    if (count) {
      nextIndex = mod(nextIndex, count)
      prevIndex = mod(prevIndex, count)
    }
    // console.log("preload: ", nextIndex, prevIndex);
    this.setState({ preloadIndex: [nextIndex, prevIndex]})
    // nextIndex is the one to preload
  }

  handleNextClick = () => {
    const { images } = this.props
    const count = images.length > 0 ? images.length : 0
    const indexLatest = this.state.viewIndex
    let viewIndex = indexLatest
    viewIndex += 1
    if (count) {
      viewIndex = mod(viewIndex, count)
    }
    this.setState({ indexLatest, viewIndex })
    this.preload(viewIndex)
  }

  handlePrevClick = () => {
    const { images } = this.props
    const count = images.length > 0 ? images.length : 0
    const indexLatest = this.state.viewIndex
    let viewIndex = indexLatest
    viewIndex -= 1
    if (count) {
      viewIndex = mod(viewIndex, count)
    }
    this.setState({ indexLatest, viewIndex })
    this.preload(viewIndex)
  }

  get caption() {
    const { captions } = this.props
    const { viewIndex } = this.state
    const found = captions.find(caption => {
      return caption.start <= viewIndex && caption.end > viewIndex
    })
    const { caption } = found
    return caption
  }

  handleOnClick = () => {
    const {
      hasInfo,
      waypoint,
      onNavDispatch,
      direction,
      currentMouseTarget: { target }
    } = this.props
    if (onNavDispatch) onNavDispatch("main")
    if (waypoint === "main" && target !== "video") {
      if (direction === "next") {
        this.handleNextClick()
      } else if (direction === "prev") {
        this.handlePrevClick()
      } else if (direction === "up") {
        onNavDispatch("header")
      } else if (direction === "down" && hasInfo) {
        onNavDispatch("footer")
      }
    }
  }


  handleInfoLinkClick = () => {
    const { hasInfo, onNavDispatch } = this.props
    if (hasInfo && onNavDispatch) onNavDispatch("footer")
  }

  handleTitleLinkClick = () => {
    const { onNavDispatch } = this.props
    if (onNavDispatch) onNavDispatch("main")
  }

  render () {
    const {
      // mouseDispatch,
      isTabletOrMobile,
      hasInfo,
      waypoint,
      direction,
      currentMouseTarget: { target },
      count,
      images,
      subtitle,
      title,
      year,
      wWidth,
      wHeight
    } = this.props
    const { viewIndex, preloadIndex } = this.state
    const { offset } = canUseDom ? vhCheck() : {}
    return (
      <React.Fragment>
        <GalleryWrapper
          offset={offset}
          onClick={!isTabletOrMobile && this.handleOnClick}
        >
          {
            images && images.length > 0
              ? (
                <BindKeyboardSwipeableViews
                  index={ viewIndex }
                  onChangeIndex={ this.handleChangeIndex }
                  enableMouseEvents
                  style={{width: '100%', height: '100%'}}
                >
                  {
                    images.map((view, index) => {
                      if (view.isImage === false) {
                        return <Video video={view} playing={viewIndex === index} wWidth={wWidth} wHeight={wHeight} />
                      } else {
                        return <Image image={view} wWidth={wWidth} wHeight={wHeight} preloadIndex={preloadIndex} index={index} viewIndex={viewIndex} />
                      }
                    })
                  }
                </BindKeyboardSwipeableViews>
              )
              : 'No images.'
          }
        </GalleryWrapper>
        <GalleryFooter offset={offset} onClick={!isTabletOrMobile && this.handleOnClick}>
          <GalleryTitle>
            <Title
              onClick={isTabletOrMobile && this.handleTitleLinkClick}
              target={target}
              direction={direction}
              isTabletOrMobile={isTabletOrMobile}
              waypoint={waypoint}
            >
              {title}{subtitle && `, ${subtitle}`}{year && `, ${year}`}
            </Title>
            {
              hasInfo &&
              <InfoLink
                onClick={isTabletOrMobile && this.handleInfoLinkClick}
                target={target}
                direction={direction}
                isTabletOrMobile={isTabletOrMobile}
                waypoint={waypoint}
              >{`Info`}</InfoLink>
            }
          </GalleryTitle>
          <ImgCaption>{this.caption}</ImgCaption>
          <ImgCount>{`${viewIndex + 1} / ${count}`}</ImgCount>
        </GalleryFooter>
      </React.Fragment>
    )
  }
}

SwipeableGallery.propTypes = {
  isTabletOrMobile: PropTypes.bool,
  hasInfo: PropTypes.bool,
  waypoint: PropTypes.string,
  onNavDispatch: PropTypes.function,
  direction: PropTypes.string,
  currentMouseTarget: PropTypes.object,
  mouseDispatch: PropTypes.function,
  subtitle: PropTypes.string,
  year: PropTypes.string,
  captions: PropTypes.array,
  count: PropTypes.number,
  images: PropTypes.array,
  title: PropTypes.string,
  wWidth: PropTypes.number,
  wHeight: PropTypes.number
}

function Gallery({gallery, ...args}) {
  const images = gallery.reduce((acc, val) => acc.concat(val.images), [])
  const captions = () => {
    let length = 0
    return gallery.map(({caption, images}) => {
      const start = length
      length = length + images.length
      return {
        caption,
        start,
        end: length
      }
    })
  }
  const waypoint = useContext(WaypointContext)
  const onNavDispatch = useContext(NavDispatch)
  const mouseDispatch = useContext(MouseDispatch)
  const currentMouseTarget = useContext(MouseContext)
  const direction = useContext(MouseCordsContext)
  const size = useContext(SizeContext)

  // MQs
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return <SwipeableGallery
    isTabletOrMobile={isTabletOrMobile}
    waypoint={waypoint}
    onNavDispatch={onNavDispatch}
    mouseDispatch={mouseDispatch}
    currentMouseTarget={currentMouseTarget}
    direction={direction}
    captions={captions()}
    count={images.length}
    images={images}
    {...size}
    {...args}
  />
}

Gallery.propTypes = {
  hasInfo: PropTypes.bool,
  subtitle: PropTypes.string,
  year: PropTypes.string,
  title: PropTypes.string,
  gallery: PropTypes.array
}

export default Gallery
